import React,{useState} from "react";
import Container from "react-bootstrap/Container";
import { Navbar, Image, Nav, NavDropdown } from "react-bootstrap";


import logo from "../../../assets/logo.svg";
import AnimatedCursors from "../../AnimatedCursors";
import { motion } from "framer-motion";
import MenuIcon from "./MenuIcon";
import { Link } from "react-router-dom";
import "./menuoffcanvas.css";

function StickyNavbar() {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);


  const navbar = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "transparent",
    borderBottom: "0.00001px solid #757575",
  };
  const navstyle = {
    color: "#FFFFFF",
    fontSize: 0.8 + "rem",
    textDecoration: 'none', 
    marginLeft:1.2+'rem',
    marginTop:0.7+'rem',
    paddingLeft:0.4+'rem',
    paddingRight:0.4+'rem',
    paddingTop:0.3+'rem',
    paddingBottom:0.3+'rem'
  };
  const navInnerStyle = {
    display: "flex",
    gap: 2,
    alignItems: "center",
    color: "#fffff",
  };



  
  return (
    <>
      <Navbar style={navbar}>
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <Image src={logo} width={115} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="navbodylinking">
             
               <Link to="/" className="nav-children" style={navstyle}>
                HOME
                </Link>
            
              
              <Link to="/about" className="nav-children" style={navstyle}>
                ABOUT
                </Link>
             
             
              <Link to="/managements" className="nav-children" style={navstyle}>
                MANAGEMENTS
                </Link>
             
             
                <NavDropdown title="PROJECTS" id="basic-nav-dropdown" style={{marginLeft:1.2+'rem', color:'white'}}>
                 
              <NavDropdown.Item >
              <Link to="/finished" className="nav-children" style={{color:'rgb(3, 23, 36)'}}>
                Finished Projects
                </Link>
              </NavDropdown.Item>
             
              <NavDropdown.Item >
              <Link to="/unfinish" className="nav-children" style={{color:'rgb(3, 23, 36)'}}>
              Unfinished Projects
              </Link></NavDropdown.Item>
            </NavDropdown>
             
            
              <Link to="/gallery" className="nav-children" style={navstyle}>
                GALLERY
                </Link>
            
              
              <Link to="/contact" >
                   <div style={{
                  ...navstyle,
                  backgroundColor: "#031724",
                  borderRadius: "5px",
                  marginLeft: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop:"10px",
                  paddingBottom:'10px',
                  marginTop:'2px',
                 textDecoration:'none'
                }}>
                CONTACT
                </div>
                </Link>
             
            </Nav>
            <div className="menuicon">
              <MenuIcon />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    
    </>
  );
}

export default StickyNavbar;
