import React from 'react';
import AnimatedCursor from "react-animated-cursor";

function AnimatedCursors() {
  // Check if the device is a mobile device
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Render the AnimatedCursor component conditionally
  return (
    <>
      {!isMobileDevice && (
        <AnimatedCursor
          innerSize={5}
          outerSize={36}
          color='95,95,95'
          outerAlpha={0.25}
          innerScale={0.7}
          outerScale={1.5}
          outerStyle={{ border: '1.5px solid #D9D9D9' }}
          innerStyle={{ border: '2px solid #FFFFFF' }}
          trailingSpeed={10}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link'
          ]}
        />
      )}
    </>
  );
}

export default AnimatedCursors;
