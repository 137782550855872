import React from 'react'
import StickyNavbar from '../../reuse-component/Navbar/StickyNavbar'
import Contactusbg from '../../../assets/Contactusbg.png'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'

function HeroSection() {
   
    
  return (
    <div id="hero" style={{ width: '100%', backgroundImage: `url(${Contactusbg})`, backgroundSize:'cover' ,height: 'auto', marginTop: '0px', paddingLeft: 0, paddingRight: 0, paddingBottom:4+'rem' }}>
       
     <StickyNavbar />
     <Container >
      <Row style={{paddingTop:8+'rem'}}>
        <Col xs={12} sm={12} md={6} lg={6} style={{display:"flex", alignItems:'center'}}>
        <div>
          <h2 style={{fontSize:3.7+'rem',color:'#ffffff'}}><strong>Get in <br/><span style={{color:'#0187F2'}}> touch with us</span></strong></h2>
          <span style={{color:'#ffffff', fontSize:0.9+'rem'}}>Our Core values are based on professionalism,<br/> integrity and respect for each client and project we undertake. </span>
        </div><br/>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
        <Form>
      <Form.Group >
        <Form.Label style={{color:'#ffffff',fontSize:0.8+'rem'}}>Full Name</Form.Label>
        <Form.Control size="lg" type="text" placeholder="Enter your fullname" />
      </Form.Group><br/>
      <Form.Group size="lg"  >
        <Form.Label style={{color:'#ffffff',fontSize:0.8+'rem'}}>Email</Form.Label>
        <Form.Control size="lg"  type="text" placeholder="Enter your email" />
      </Form.Group><br/>
      <Form.Group >
        <Form.Label style={{color:'#ffffff',fontSize:0.8+'rem'}}>Subject</Form.Label>
        <Form.Control size="lg" type="text" placeholder="Message Subject" />
      </Form.Group><br/>
      <Form.Group >
        <Form.Label style={{color:'#ffffff',fontSize:0.8+'rem'}}>Example textarea</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group><br/>

      <Button variant="primary">Contact Us</Button>
    </Form>
        </Col>
      </Row>
       
     </Container>
    </div>
  )
}

export default HeroSection
