import React from "react";
import { Container } from "react-bootstrap";
import HeroSection from "../unfinished/components/HeroSection";

import "./emirate.css";

import Footer from "../../components/homepage/components/Footer";

import Denver from "../homepage/components/Denver";
import Emirate from "../homepage/components/Emirate";

function Unfinished() {
  

  return (
    <Container fluid style={{ width: 100 + "%", padding: 0 }}>
      <HeroSection />
     
      <Denver />
      <Emirate />
     

      <Footer />
    </Container>
  );
}

export default Unfinished;