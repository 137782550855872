import React from "react";
import { Container, Carousel } from "react-bootstrap";
import "../homepage.css";
import AnimatedDiv from "../../AnimatedDiv";

function Testimonial() {
  return (
    <div className="testimonialbody">
    <Container className="Testimonial">
        <center>
        <AnimatedDiv transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
      <h2 style={{color:"#0187F2"}}><strong>Testimonial</strong></h2>
      <p className="subhero">
        With the increasing need to work from home and a corresponding need for
        personal efficiency post-pandemic
      </p>

      <Carousel indicators={true} controls={false} style={{ width: 80 + '%', marginTop:3+"rem" }}>
                    <Carousel.Item interval={6000}>

                        <div className="textbodyx">
                           

                            <div className="carouselTextbody">
                                <h6 style={{fontSize:0.9+"rem"}} >Charles James</h6>
                                <p className="testimonial-message" style={{fontSize:0.8+"rem"}} > The companies that own the future are those that are forward thinking in all their approaches, anticipating market needs, trends, and technology, and using all these to their advantage to create the best products and services. Cranium is committed to building homes that are sustainably ready for tomorrow’s technology from simple automation and energy efficiency to full-on smart home capabilities yet are also aesthetically pleasing and practical in today’s world.
 

                                </p>
                            </div> 
                        </div>

                    </Carousel.Item>
                    <Carousel.Item interval={6000}>

                        <div className="textbodyx">
                           
                        <div className="carouselTextbody">
                                <h6 style={{fontSize:0.9+"rem"}} >Charles James</h6>
                                <p className="testimonial-message" style={{fontSize:0.8+"rem"}} > The companies that own the future are those that are forward thinking in all their approaches, anticipating market needs, trends, and technology, and using all these to their advantage to create the best products and services. Cranium is committed to building homes that are sustainably ready for tomorrow’s technology from simple automation and energy efficiency to full-on smart home capabilities yet are also aesthetically pleasing and practical in today’s world.
 

                                </p>
                            </div> 
                        </div>

                    </Carousel.Item>
                    <Carousel.Item interval={6000}>

                        <div className="textbodyx">
                          

                        <div className="carouselTextbody">
                                <h6 style={{fontSize:0.9+"rem"}} >Charles James</h6>
                                <p className="testimonial-message" style={{fontSize:0.8+"rem"}} > The companies that own the future are those that are forward thinking in all their approaches, anticipating market needs, trends, and technology, and using all these to their advantage to create the best products and services. Cranium is committed to building homes that are sustainably ready for tomorrow’s technology from simple automation and energy efficiency to full-on smart home capabilities yet are also aesthetically pleasing and practical in today’s world.
 

                                </p>
                            </div> 
                        </div>

                    </Carousel.Item>
                </Carousel>
       </AnimatedDiv>
      
      </center>
    </Container>
    </div>
  );
}

export default Testimonial;
