import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";




import { useEffect } from "react";
import {  useLocation } from "react-router-dom";


import HomePage from "./components/homepage/HomePage";
import AnimatedCursors from "./components/AnimatedCursors";
import AboutPage from "./components/about/AboutPage";
import Management from "./components/management/Management";
import Gallery from "./components/gallery/Gallery";
import Contact from "./components/contact/Contact";
import Finished from "./components/finished/Finished";
import Unfinished from "./components/unfinished/Unfinished";
import Denver from "./components/denver/Denver";
import Emirate from "./components/emirate/Emirate";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}





const RootApplication = () => {
  return (
    <div >
   
      <Routes>
        
        <Route path="/" exact element={<HomePage />} />
        <Route path="/about" exact element={<AboutPage /> } />
        <Route path="/managements" exact element={<Management /> } />
        <Route path="/gallery" exact element={<Gallery /> } />
        <Route path="/contact" exact element={<Contact /> } />
        <Route path="/finished" exact element={<Finished /> } />
        <Route path="/unfinish" exact element={<Unfinished /> } />
        <Route path="/denver" exact element={<Denver /> } />
        <Route path="/emirate" exact element={<Emirate /> } />
       
      
        {/* <Route path="*" element={<Notfound />} />  */}
        
      </Routes>
     
      
    </div>
  );
};

function App() {
  return (
    <Router >
          <AnimatedCursors />
          <ScrollToTop />
           <RootApplication />
        
     
    </Router>
  );
}

export default App;
