import React from 'react'
import StickyNavbar from '../../reuse-component/Navbar/StickyNavbar'
import Aboutusbanner from '../../../assets/Aboutusbanner.png'
import Container from 'react-bootstrap/esm/Container'

function HeroSection() {
   
    
  return (
    <div id="hero" style={{ width: '100%', backgroundImage: `url(${Aboutusbanner})`, backgroundSize:'cover' ,height: '100vh', marginTop: '0px', paddingLeft: 0, paddingRight: 0 }}>
       
     <StickyNavbar />
     <Container style={{height:100+'%',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <h1 style={{fontSize:3.4+'rem',color:'#ffffff'}}>About Us</h1>
     </Container>
    </div>
  )
}

export default HeroSection
