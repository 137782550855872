import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/logo.svg'

import { Container, Offcanvas, Image , Navbar, Modal } from 'react-bootstrap';
import './menuoffcanvas.css';

import { motion, easeOut } from 'framer-motion';
import CloseIcon from './CloseIcon';
import AnimatedCursors from '../../AnimatedCursors';

const Arrow =  () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="12" viewBox="0 0 41.92 18.975">
  <g id="Group_5" data-name="Group 5" transform="translate(-1105 -327.855)">
    <line id="Line_1" data-name="Line 1" x2="40" transform="translate(1105 338)" fill="none" stroke="#fff" stroke-width="2"/>
    <path id="Path_1" data-name="Path 1" d="M3612.468,294.519l8.095,9.122-8.095,8.5" transform="translate(-2475 34)" fill="#fff" stroke="#fff" stroke-width="2"/>
  </g>
</svg>
    )
};

function MenuIcon() {
  const [isNavHovered, setIsNavHovered] = useState(false);

  const handleNavMouseEnter = () => {
    setIsNavHovered(true);
  };

  const handleNavMouseLeave = () => {
    setIsNavHovered(false);
  };

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
const handleShowOffcanvas = () => setShowOffcanvas(true);



const [showModal, setShowModal] = useState(false); 
  
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);


  const navInnerStyle = {
    display: 'flex',
    alignItems: "center",
    color: '#00000',
    fontWeight:'bolder',
    fontSize:1.2+"rem",
    marginBottom:1+'rem'
   
  };

  return (
    <>
      <svg
        onClick={handleShowOffcanvas}
        id="menu-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="85"
        height="18"
        viewBox="0 0 102 29"
        onMouseEnter={handleNavMouseEnter}
        onMouseLeave={handleNavMouseLeave}
      >
        <g id="Group_3" data-name="Group 3" transform="translate(-1816.951 148.351)">
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="32"
            height="3"
            rx="2"
            transform={isNavHovered ? "translate(1816.951 -147.351)" : "translate(1816.951 -147.351)"}
            fill="#fff"
          />
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="32"
            height="3"
            rx="2"
            transform={isNavHovered ? "translate(1824.951 -135.351)" : "translate(1816.951 -135.351)"}
            fill="#fff"
            style={{ transition: "transform 0.3s ease" }} // Apply transition to smoothly animate position change
          />
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="32"
            height="3"
            rx="2"
            transform={isNavHovered ? "translate(1816.951 -123.351)" : "translate(1816.951 -123.351)"}
            fill="#fff"
          />
        </g>
        <text id="MENU" transform="translate(44 21)" fill="#fefefe" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="00">
          <tspan x="0" y="0">MENU</tspan>
        </text>
      </svg>

      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} className="offcanvas">

        <AnimatedCursors />
        <Container className="offcanvasbody">
          <Offcanvas.Header closeButton={false} style={{ display: "flex", justifyContent: "space-between" }} closeVariant='white' >
            <Offcanvas.Title style={{ fontSize: 0.7 + 'rem', color: '#0187F2', fontFamily: "Space Mono" }}>NAVIGATION</Offcanvas.Title>
            <CloseIcon onClick={handleCloseOffcanvas} />
          </Offcanvas.Header>
          <Offcanvas.Body style={{ width: 100 + '%' }} >
          
            <ul id="navigationnav">
              <li>
                <motion.div initial={{ opacity: 0, x: -30, }} animate={{ opacity: 1, x: 0, }} transition={{ duration: 0.3, delay: 0.2, timingFunction: easeOut }}>
                  <Link to="/" style={{ textDecoration: 'none', color: 'white', }}>
                    <div closeButton style={navInnerStyle} id="nav-element">
                      <span>Home</span>
                      <Arrow />
                    </div>
                  </Link>
                </motion.div>
              </li>
              <li>
                <motion.div initial={{ opacity: 0, x: -30, }} animate={{ opacity: 1, x: 0, }} transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}>
                  <Link to="/about" style={{ textDecoration: 'none', color: 'white', }}>
                    <div closeButton style={navInnerStyle} id="nav-element">
                      <span>About</span>
                      <Arrow />
                    </div>
                  </Link>
                </motion.div>
              </li>
              <li>
                <motion.div initial={{ opacity: 0, x: -30, }} animate={{ opacity: 1, x: 0, }} transition={{ duration: 0.3, delay: 0.6, timingFunction: easeOut }}>
                  <Link to="/managements" style={{ textDecoration: 'none', color: 'white', }}>
                    <div closeButton style={navInnerStyle} id="nav-element">
                      <span>Managements</span>
                      <Arrow />
                    </div>
                  </Link>
                </motion.div>
              </li>
              <li>
                <motion.div initial={{ opacity: 0, x: -30, }} animate={{ opacity: 1, x: 0, }} transition={{ duration: 0.3, delay: 0.8, timingFunction: easeOut }}>
                <Link style={{ textDecoration: 'none', color: 'white', }}>
                    <div onClick={handleShowModal} closeButton style={navInnerStyle} id="nav-element">
                      <span>Projects</span>
                      <Arrow />
                    </div>
               </Link>
                </motion.div>
              </li>
              <li>
                <motion.div initial={{ opacity: 0, x: -30, }} animate={{ opacity: 1, x: 0, }} transition={{ duration: 0.3, delay: 1.0, timingFunction: easeOut }}>
                  <Link to="/gallery" style={{ textDecoration: 'none', color: 'white', }}>
                    <div closeButton style={navInnerStyle} id="nav-element">
                      <span>Gallery</span>
                      <Arrow />
                    </div>
                  </Link>
                </motion.div>
              </li>
              <li>
                <motion.div initial={{ opacity: 0, x: -30, }} animate={{ opacity: 1, x: 0, }} transition={{ duration: 0.3, delay: 1.2, timingFunction: easeOut }}>
                  <Link to="/contact" style={{ textDecoration: 'none', color: 'white', }}>
                    <div closeButton style={navInnerStyle} id="nav-element">
                      <span>Contact</span>
                      <Arrow />
                    </div>
                  </Link>
                </motion.div>
              </li>
            
            </ul>
        
            <Container fluid className='offcanvassbottom' >
                <div style={{color:'white'}}><ul style={{listStyle:'none',fontSize:0.9+"rem"}}></ul></div>
                <Navbar.Brand >
          <Image src={logo} width={100} />
        </Navbar.Brand>
            </Container>
            <br/>
            

          </Offcanvas.Body>
        </Container>


        <Modal show={showModal} onHide={handleCloseModal}>
        <AnimatedCursors />
        <Modal.Header style={{display:'flex', justifyContent:'space-between'}} closeButton={false}>
          <Modal.Title style={{fontSize:1+'rem',color:'black'}}>Project</Modal.Title>
          <span style={{color:'black'}} onClick={handleCloseModal}>Back</span>
        </Modal.Header>
        <Modal.Body>
  
  <div style={{display:'flex',flexDirection:'column',gap:0,}}>

  <motion.div >
          <Link to="/finished" style={{ textDecoration: 'none', color: 'black', }}>
            <div closeButton style={navInnerStyle} id="nav-element">
              <span>Finished Projects</span>
            
            </div>
          </Link>
        </motion.div>

        <motion.div >
          <Link to="/unfinish" style={{ textDecoration: 'none', color: 'black', }}>
            <div closeButton style={navInnerStyle} id="nav-element">
              <span>Unfinished Projects</span>
            
            </div>
          </Link>
        </motion.div>


 
  </div>
  

  
 
</Modal.Body>

       
      </Modal>

      </Offcanvas>
    </>
  );
}

export default MenuIcon;
