import React from 'react'
import StickyNavbar from '../../reuse-component/Navbar/StickyNavbar'
import Emiratebg from '../../../assets/Emiratebg.png'
import Container from 'react-bootstrap/esm/Container'

function HeroSection() {
   
    
  return (
    <div id="hero" style={{ width: '100%', backgroundImage: `url(${Emiratebg})`, backgroundSize:'cover' ,height: '100vh', marginTop: '0px', paddingLeft: 0, paddingRight: 0 }}>
       
     <StickyNavbar />
     <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Container>
        <h1 style={{ fontSize: '3.4rem', color: '#ffffff', textAlign: 'center' }}>Emirate Prime Apartments</h1>
    </Container>
</div>


    </div>
  )
}

export default HeroSection
