import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import DenverApartment from "../../../assets/DenverApartment.png";
import { Link } from "react-router-dom";

import '../homepage.css'
import AnimatedDiv from "../../AnimatedDiv";

function Emirate() {
  return (
    <Container fluid className="Denver">
      <Row>
      <Col
        className="order-lg-2"
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{
            padding: 0,
            paddingTop: 5 + "rem",
            paddingBottom: 5 + "rem",
          }}
        >
          <Image src={DenverApartment} width={100 + "%"} />
        </Col>
       
      <Col xs={12} sm={12} md={12} lg={6} className="order-lg-1 message2-container" >
      <AnimatedDiv  transition={{ duration: 0.5, delay: 0.7, timingFunction: 'easeOut' }}>
          <div
            className="message2body"
          >
            <h2 style={{ color: "#01A5F5" }}>
              <strong>Emirate Prime Apartments</strong>
            </h2>
            <h6 style={{ color: "#FFFFFF" }}>The Emerald Guzape</h6>

            <p style={{ color: "#FFFFFF", paddingTop:3+"rem", fontSize:0.8+"rem", lineHeight:189+"%" }}>
            Emirate apartments is a five bedroom semi detached terrace duplex with BQ 
designed for a modern family with a flair for discreet architecture and space 
management infused with nature and beautiful landscaping, Denver 
apartments is situated 
            </p>
            
            <div style={{color:"white", fontSize:0.84+"rem", paddingTop:2+"rem"}}>
            <h6>Facilities</h6>
            <Row>
              <Col xs={6} sm={6} md={4} lg={4}><li>CCTV & Intercom</li></Col>
              <Col xs={6} sm={6} md={4} lg={4}><li>Ample Parking</li></Col>
              <Col xs={6} sm={6} md={4} lg={4}><li>Street Lights</li></Col>
              <Col xs={6} sm={6} md={4} lg={4}><li>landscaping</li></Col>
              <Col xs={6} sm={6} md={4} lg={4}><li>Shops</li></Col>
              <Col xs={6} sm={6} md={4} lg={4}><li>Uniform Security</li></Col>
              <Col xs={6} sm={6} md={4} lg={4}><li>Interlocked Packing</li></Col>
              <Col xs={6} sm={6} md={4} lg={4}><li>Serene-Environment</li></Col>
              <Col xs={6} sm={6} md={4} lg={4}><li>Asphalt Paved Road</li></Col>
            
            </Row>
           
            </div>

           <br/>
           <Link to="/emirate">
            <button style={{color:"#0187F2", backgroundColor:'transparent', borderColor:"transparent",marginLeft:-1+"rem"}} className='btn btn-light'>Explore</button>
            </Link>

          </div>

         </AnimatedDiv>
    
        </Col>
       
      </Row>
    </Container>
  );
}

export default Emirate;
