import React from 'react'
import { Container } from 'react-bootstrap'

import mapbg from '../../../assets/mapbg.png'

function Address() {
  return (
    <Container fluid style={{ width: '100%', backgroundImage: `url(${mapbg})`, backgroundSize:'cover' ,height: 'auto', marginTop: '5rem', paddingLeft: 0, paddingRight: 0 }}>
      <div style={{ height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center',gap:1+'rem',paddingLeft:1+'rem',paddingRight:1+'rem',paddingTop:2+'rem',paddingBottom:2+'rem' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="61" height="93" viewBox="0 0 81 103">
  <g id="loca" transform="translate(-25.883)">
    <circle id="Ellipse_6" data-name="Ellipse 6" cx="20.5" cy="20.5" r="20.5" transform="translate(45.883 42)" fill="#0187f2" opacity="0.15"/>
    <circle id="Ellipse_7" data-name="Ellipse 7" cx="40.5" cy="40.5" r="40.5" transform="translate(25.883 22)" fill="#0187f2" opacity="0.15"/>
    <circle id="Ellipse_8" data-name="Ellipse 8" cx="5" cy="5" r="5" transform="translate(61.883 58)" fill="#0187f2"/>
    <path id="Path_7" data-name="Path 7" d="M77.339,0C65.022,0,55,9.585,55,21.362c0,5.609,2.556,13.067,7.6,22.169a163.813,163.813,0,0,0,11.17,17.2,4.433,4.433,0,0,0,7.151,0,164.534,164.534,0,0,0,11.17-17.2c5.033-9.1,7.59-16.558,7.59-22.169C99.679,9.585,89.657,0,77.339,0Zm0,31.275a8.945,8.945,0,1,1,6.316-2.62A8.936,8.936,0,0,1,77.339,31.275Z" transform="translate(-10.675)" fill="#0bf"/>
  </g>
</svg>
      <span style={{color:'#ffffff',fontSize:0.8+'rem'}}>Our building is situated in a serene and beautiful environment. It is less than 500metres in proximity to FIRS Katampe.</span>

      </div>
    </Container>
  )
}

export default Address
