import React from "react";
import { Container, Row, Col, Image} from "react-bootstrap";
import CEOimage from "../../../assets/CEOimage.png";
import MissionVissionbg2 from '../../../assets/MissionVissionbg2.png';

import '../aboutpage.css';
import AnimatedDiv from "../../AnimatedDiv";

function CompanyStatement() {
  return (
    <Container
      fluid
      style={{
        marginTop: 8.2 + "rem",
        width: "100%",
        backgroundImage: `url(${MissionVissionbg2})`,
        backgroundSize: "cover",
        height: "auto",
        paddingLeft: "5%",
        paddingRight: "5%",
        paddingTop: 2.5 + "rem",
        paddingBottom: 2.5 + "rem",
      }}
    >
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} className="companystatement">
        <AnimatedDiv transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <h6 style={{ color: "#ffffff", fontSize: 1.8 + "rem" }}>
            Company Statement
          </h6>
          <p
            style={{
              color: "#ffffff",
              fontSize: 0.8 + "rem",
              lineHeight: "26px",
            }}
          >
            Cranium construction is an indigenous real estate and construction
            company which was designed to provide bespoke building and
            construction services across board for both affordable and luxurious
            properties. while incorporating proven, professional state of the
            art techniques specializing in marketing, listing and selling of new
            and resale luxury homes, residential communities condominiums, home
            site, underdeveloped lands as well as commercial and investment
            opportunities.
            </p>
            <p
            style={{
                color: "#ffffff",
                fontSize: 0.8 + "rem",
                lineHeight: "26px",
              }}>
             We aim to provide tailored made houses across
            locations and terrains in bridging the housing deficit across
            Nigeria.
          </p>

          <div style={{color:'#ffffff',display:'flex',flexDirection:'column',gap:'0px'}}>
            <h6>Engr. ABDULLAHI SADIQ</h6>
            <span style={{fontSize:0.8+'rem'}}>(MD/CEO)(mnse)(M.coren)</span>
          </div>
          <br/>
          </AnimatedDiv>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
            <center>
              <br/><br/>
              <AnimatedDiv transition={{ duration: 0.5, delay: 0.8, timingFunction: 'easeOut' }}>
            <Image src={CEOimage}   />
            </AnimatedDiv>
            </center>
        
        </Col>
      </Row>
    </Container>
  );
}

export default CompanyStatement;
