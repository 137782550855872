import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import introimage from "../../../assets/introimage.png";
import { Link } from "react-router-dom";

import "../homepage.css";
import AnimatedDiv from "../../AnimatedDiv";

function Value() {
  return (
    <Container className="introsection">
      <Row>
        <Col 
         xs={12}
         sm={12}
         md={6}
         lg={6}>
           <AnimatedDiv  transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <h2 className="introsection-header">
            Our Value <br/><span style={{ color: "#0187F2" }}>Preposition</span>
          </h2>
          </AnimatedDiv>
          <AnimatedDiv  transition={{ duration: 0.5, delay: 0.7, timingFunction: 'easeOut' }}>
          <p className="introsection-paragraph">
            The companies that own the future are those that are forward
            thinking in all their approaches, anticipating market needs, trends,
            and technology, and using all these to their advantage to create the
            best products and services. Cranium is committed to building homes
            that are sustainably ready for tomorrow’s technology from simple
            automation and energy efficiency to full-on smart home capabilities
            yet are also aesthetically pleasing and practical in today’s world.
          </p>
          <Link to="/about">
          <button style={{ color: "#0187F2" }} className="btn btn-light">
            About us
          </button>
          </Link>
          </AnimatedDiv>
        </Col>
        <Col  
        xs={12}
         sm={12}
         md={6}
         lg={6}>
          <AnimatedDiv transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <Image src={introimage} width={100 + "%"} height={100 + "%"} />
            </AnimatedDiv> 
        
        </Col>
      </Row>
    </Container>
  );
}

export default Value;
