import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MissionVissionbg from '../../../assets/MissionVissionbg.png';
import AnimatedDiv from "../../AnimatedDiv";

function Mission() {
  return (
    <Container fluid style={{marginTop:8.2+'rem', width: '100%', backgroundImage: `url(${MissionVissionbg})`, backgroundSize:'cover' ,height: 'auto', paddingLeft:'5%',paddingRight:'5%',paddingTop:2.5+'rem',paddingBottom:2.5+'rem'}}>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <AnimatedDiv transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <h6 style={{color:'#ffffff',fontSize:1.8+"rem"}}>Mission</h6>
          <p style={{color:'#ffffff',fontSize:0.8+"rem",lineHeight:'26px'}}>
            At Cranium construction is to be the gold standard model of real
            estate and construction in Nigeria, while delivering our projects
            with out most precision within the shortest period of time, and also
            providing our customers with the quality construction services at
            fair and market competitive prices
          </p>
          </AnimatedDiv>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}></Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}></Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <AnimatedDiv transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <h6 style={{color:'#ffffff',fontSize:1.8+"rem"}}>Our Vision</h6>
          <p style={{color:'#ffffff',fontSize:0.8+"rem",lineHeight:'26px'}}>
            Our vision is to be at the forefront of providing real estate value,
            solutions and satisfaction to aspiring property owners across the
            residential and and commercial landscape.
          </p>
          </AnimatedDiv>
        </Col>
      </Row>
    </Container>
  );
}

export default Mission;
