import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeroSection from "../gallery/components/HeroSection";

import Gallery1 from "../../assets/Gallery1.png";
import Gallery2 from "../../assets/Gallery2.png";
import Gallery3 from "../../assets/Gallery3.png";
import Gallery4 from "../../assets/Gallery4.png";
import Gallery5 from "../../assets/Gallery5.png";
import Gallery6 from "../../assets/Gallery6.png";

import "./gallery.css";
import Footer from "../../components/homepage/components/Footer";
import AnimatedDiv from "../AnimatedDiv";

function AboutPage() {
  return (
    <Container fluid style={{ width: 100 + "%", padding: 0 }}>
      <HeroSection />
      <Container style={{ marginTop: 8 + "rem" }}>
      <AnimatedDiv transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
        <div>
          <h2 style={{ fontSize: 2.7 + "rem" }}>
            <strong>
              Feature <br />
              <span style={{ color: "#0187F2" }}> Gallery</span>
            </strong>
          </h2>
          <span className="headparagraph">
            Our Core values are based on professionalism,
            <br /> integrity and respect for each client and project we
            undertake.{" "}
          </span>
        </div>
        </AnimatedDiv>
        <br />

        <Row style={{ marginBottom: 2 + "rem" }}>
          <Col className="firstimage" xs={12} sm={12} md={7} lg={7}>
            
            <Image src={Gallery1} width={100 + "%"} height={100 + "%"} />
          </Col>
          <Col xs={12} sm={12} md={5} lg={5}>
            <Image src={Gallery2} width={100 + "%"} height={100 + "%"} />
          </Col>
        </Row>
        <Row style={{ marginBottom: 2 + "rem" }}>
          <Col className="firstimage" xs={12} sm={12} md={5} lg={5}>
            <Image src={Gallery3} width={100 + "%"} height={100 + "%"} />
          </Col>
          <Col xs={12} sm={12} md={7} lg={7}>
            <Image src={Gallery4} width={100 + "%"} height={100 + "%"} />
          </Col>
        </Row>
        <Row style={{ marginBottom: 2 + "rem" }}>
          <Col className="firstimage" xs={12} sm={12} md={7} lg={7}>
            <Image src={Gallery5} width={100 + "%"} height={100 + "%"} />
          </Col>
          <Col xs={12} sm={12} md={5} lg={5}>
            <Image src={Gallery6} width={100 + "%"} height={100 + "%"} />
          </Col>
        </Row>
      </Container>

      <Footer />
    </Container>
  );
}

export default AboutPage;
