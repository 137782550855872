import React from "react";
import { Container, Image, Col, Row, Form, Button } from "react-bootstrap";
import { Link as LinkRoll } from "react-scroll";

import logo from "../../../assets/logo.svg";

function Footer() {
  return (

    <div className="footerbody">
    <Container fluid style={{marginTop:3+'rem'}} className="footer">
      <Container>
      <LinkRoll to="hero" spy={true} smooth={true} offset={-70} duration={100} >
        <Image src={logo} width={115} />
        </LinkRoll>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5}>
            <p
              style={{
                fontSize: 0.8 + "rem",
                marginTop: 2 + "rem",
                color: "#FFFFFF",
                lineHeight: 197 + "%",
              }}
            >
              The companies that own the future are those that are forward
              thinking in all their approaches, anticipating market needs,
              trends, and technology, and using all these to their advantage to
              create the best products and services.
            </p>
            <br />
          </Col>
          <Col xs={0} sm={0} md={2} lg={2}></Col>
          <Col xs={12} sm={12} md={5} lg={5}>
            <div className="newsletterformbody">
              <h6 className="newsletterhead">Join Our Newsletter</h6>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "white",
                  borderRadius: 6 + "PX",
                }}
              >
                <Form.Control
                  style={{
                    height: 2.7 + "rem",
                    fontFamily: "poppins, sans-serif",
                    fontSize: 0.85 + "rem",
                    borderRadius: 6 + "PX",
                  }}
                  type="email"
                  placeholder="Enter your mail here"
                />
                <Button
                  style={{
                    height: 2.7 + "rem",
                    marginTop: 0 + "rem",
                    fontFamily: "POPPINS, sans-serif",
                    backgroundColor: "#0C0C0C",
                    borderColor: "#0C0C0C",
                    borderRadius: 6 + "PX",
                    paddingLeft: 1.2 + "rem",
                    paddingRight: 1.2 + "rem",
                    fontSize: 0.8 + "rem",
                  }}
                  variant="primary"
                  className="button-store"
                  type="submit"
                >
                  Subscribe
                </Button>
              </div>
              <p style={{ marginTop: 0.7 + "rem", color: "#FFFFF" ,fontSize:0.8+'rem'}}>
                * Will send you weekly updates for your better engagement.
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 4 + "rem" }}>
          <Col xs={12} sm={12} md={4} lg={4}>
            <h6 className="footer-header">CALL US</h6>
            <p>+234 8001836393</p>
            <p>+234 8001836393</p>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <h6 className="footer-header">MEET UP</h6>
            <p>
              F7 No. 4 Auchi Street, Area 1, Garki, <br />
              Abuja Nigeria
            </p>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <h6 className="footer-header">WRITE US</h6>
            <p>cranium_construct@yahoo.com</p>
          </Col>
        </Row>
        <div
          style={{
            marginTop: 5 + "rem",
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 47.833 47.833"
          >
            <g id="facebook" transform="translate(0.854 0.854)">
              <circle
                id="Ellipse_2"
                data-name="Ellipse 2"
                cx="23.063"
                cy="23.063"
                r="23.063"
                fill="none"
                stroke="#fff"
                stroke-width="1.708"
              />
              <path
                id="Path_3"
                data-name="Path 3"
                d="M26.215,36.478V24.706h3.971l.59-4.609H26.215V17.162c0-1.33.371-2.241,2.279-2.241h2.419V10.812a32.084,32.084,0,0,0-3.543-.182c-3.51,0-5.92,2.143-5.92,6.077v3.382H17.5V24.7h3.954v11.78Z"
                transform="translate(-1.597 -1.427)"
                fill="#fff"
              />
            </g>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 48.808 48.808"
          >
            <g id="twitter" transform="translate(0.872 0.872)">
              <circle
                id="Ellipse_5"
                data-name="Ellipse 5"
                cx="23.532"
                cy="23.532"
                r="23.532"
                fill="none"
                stroke="#fff"
                stroke-width="1.743"
              />
              <path
                id="Path_6"
                data-name="Path 6"
                d="M31.263,11.5H35.4L26.358,21.665,37,35.5H28.669l-6.53-8.389L14.675,35.5H10.53L20.2,24.624,10,11.5h8.544l5.894,7.666ZM29.806,33.064H32.1L17.29,13.81H14.829Z"
                transform="translate(-0.872 -0.872)"
                fill="#fff"
              />
            </g>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 46.931 46.931"
          >
            <g id="instagram" transform="translate(0.838 0.838)">
              <circle
                id="Ellipse_3"
                data-name="Ellipse 3"
                cx="22.627"
                cy="22.627"
                r="22.627"
                fill="none"
                stroke="#fff"
                stroke-width="1.676"
              />
              <path
                id="Path_4"
                data-name="Path 4"
                d="M24.444,27.87a3.817,3.817,0,1,1,3.817-3.817A3.825,3.825,0,0,1,24.444,27.87Zm11.449-3.817c0,1.581.014,3.147-.075,4.725a6.808,6.808,0,0,1-1.847,4.8,6.8,6.8,0,0,1-4.8,1.847c-1.581.089-3.147.074-4.725.074s-3.147.014-4.725-.074a6.808,6.808,0,0,1-4.8-1.847,6.8,6.8,0,0,1-1.847-4.8C12.986,27.2,13,25.631,13,24.053s-.014-3.147.074-4.725a6.808,6.808,0,0,1,1.847-4.8,6.8,6.8,0,0,1,4.8-1.847c1.581-.089,3.147-.075,4.725-.075s3.147-.014,4.725.075a6.808,6.808,0,0,1,4.8,1.847,6.8,6.8,0,0,1,1.847,4.8C35.911,20.906,35.894,22.472,35.894,24.053ZM24.444,18.179a5.874,5.874,0,1,0,5.874,5.874A5.865,5.865,0,0,0,24.444,18.179ZM30.559,28.8a1.371,1.371,0,1,0,.525.1A1.37,1.37,0,0,0,30.559,28.8Z"
                transform="translate(-1.571 -1.669)"
                fill="#fff"
              />
            </g>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 46.931 46.931"
          >
            <g id="linkedin" transform="translate(0.838 0.838)">
              <circle
                id="Ellipse_4"
                data-name="Ellipse 4"
                cx="22.627"
                cy="22.627"
                r="22.627"
                fill="none"
                stroke="#fff"
                stroke-width="1.676"
              />
              <path
                id="Path_5"
                data-name="Path 5"
                d="M30.5,13A3.5,3.5,0,0,1,34,16.5v14A3.5,3.5,0,0,1,30.5,34h-14A3.5,3.5,0,0,1,13,30.5v-14A3.5,3.5,0,0,1,16.5,13ZM18.833,21.167a1.167,1.167,0,0,0-1.167,1.167v5.833a1.167,1.167,0,0,0,2.333,0V22.333a1.167,1.167,0,0,0-1.167-1.167ZM22.333,20a1.167,1.167,0,0,0-1.167,1.167v7a1.167,1.167,0,0,0,2.333,0V23.9a4.845,4.845,0,0,1,1.625-1.159,2.22,2.22,0,0,1,1.379-.1.641.641,0,0,1,.342.219A1.061,1.061,0,0,1,27,23.5v4.667a1.167,1.167,0,0,0,2.333,0V23.5a3.354,3.354,0,0,0-.611-2.035A2.963,2.963,0,0,0,27.2,20.407a4.533,4.533,0,0,0-3,.187,6.791,6.791,0,0,0-.727.364A1.167,1.167,0,0,0,22.333,20Zm-3.5-2.333a1.167,1.167,0,1,0,.825.342A1.167,1.167,0,0,0,18.833,17.667Z"
                transform="translate(-0.907 -0.928)"
                fill="#fff"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </div>
      </Container>
    </Container>
    </div>
  );
}

export default Footer;
