import React, { useRef } from 'react';
import herovideo from '../../../assets/herovideo.mp4'
import StickyNavbar from '../../reuse-component/Navbar/StickyNavbar';
import { Link } from "react-router-dom";

function HeroSection() {
  
  const overlay = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "black", 
    opacity: 0.4,
  }
  


  const videoRef = useRef(null);

  
  return (
    <div className="hero-section" id='hero' style={{position:'relative'}}>
     
      <video
        ref={videoRef}
        style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
        muted
        loop
        autoPlay
        playsInline
        
      >
        <source src={herovideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
     
      <div style={overlay}></div>
      <StickyNavbar />

      <div className='overlaycontent'>
        <h1 style={{fontSize:3.5+'rem',color:'#FFFFFF'}}>We build Estate,<br/> Design & Construct</h1>
        <p style={{color:'#FFFFFF', fontSize:0.88+"rem"}} className='hero-text'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis 
          libero et arcu posuere, vitae facilisis nulla ultrices. Vivamus sit amet 
          augue ac sapien bibendum dapibus nec vitae metus.
        </p>
        <Link to="/finished"  >
        <button className='CTAstyle'>VIEW PROJECTS</button>
        </Link>
     
      </div>
    </div>
  );
}

export default HeroSection;
