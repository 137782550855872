import React from 'react';
import { Container } from 'react-bootstrap';
import HeroSection from './components/HeroSection';

import homebanner from '../../assets/homebanner.png';
import Introduction from './components/Introduction'; 
import Value from './components/Value';
import Denver from './components/Denver';
import Emirate from './components/Emirate';
import Testimonial from './components/Testimonial';
import Footer from './components/Footer';
import CompanyStatement from '../about/components/CompanyStatement';

function HomePage() {
  return (
    <Container fluid style={{ width: '100%', backgroundImage: `url(${homebanner})`, backgroundSize:'cover' ,height: '100vh', marginTop: '0px', paddingLeft: 0, paddingRight: 0 }}>
      <HeroSection />
      <Value />
      {/* <Introduction /> */}
      <Container className="introsectionxx" style={{justifyContent:'center' ,display:'flex', flexDirection:'column', alignItems:'center'}}>
        <h1 style={{fontSize:3.2+'rem'}}>
          <strong>Finished Projects</strong>
        </h1>
        <p style={{fontSize:0.8+"rem", justifyContent:'center', alignItems:'center', textAlign:'center'}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla 
          convallis libero et arcu<br/> posuere, vitae facilisis nulla ultrices. 
          Vivamus sit amet
        </p>
      </Container>
      <Denver />
      <Emirate />
    
      <Testimonial />
      <CompanyStatement />
      <Footer />

    </Container>
  );
}

export default HomePage;
