import React, { useState } from 'react';

function CloseIcon({ onClick }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <svg
      
      id="Close-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="18"
      viewBox="0 0 108 28"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <g id="Group_3" data-name="Group 3" transform="translate(-1816.951 153.351)">
        <rect
          id="Rectangle_2"
          data-name="Rectangle 2"
          width="32"
          height="3"
          rx="2"
          transform={isHovered ? "translate(1816.951 -147.351) rotate(25)" : "translate(1816.951 -147.351)"}
          fill="#fff"
          style={{ transition: "transform 0.3s ease" }} // Apply transition to smoothly animate rotation
        />
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="32"
          height="3"
          rx="2"
          transform={isHovered ? "translate(1816.951 -135.351) rotate(-25)" : "translate(1816.951 -135.351)"}
          fill="#fff"
          style={{ transition: "transform 0.3s ease" }} // Apply transition to smoothly animate rotation
        />
      </g>
      <text
        id="CLOSE"
        transform="translate(44 21)"
        fill="#fefefe"
        fontSize="20"
        fontFamily="Poppins-SemiBold, Poppins"
        fontWeight="500"
      >
        <tspan x="0" y="0">CLOSE</tspan>
      </text>
    </svg>
  );
}

export default CloseIcon;
