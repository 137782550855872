import React from 'react';
import { Container } from 'react-bootstrap';
import HeroSection from '../management/components/HeroSection';
import ManagementSlide from '../management/components/ManagementSlide';


import './management.css'

import CompanyStatement from '../about/components/CompanyStatement';

import Footer from '../../components/homepage/components/Footer';

import './management.css'


function AboutPage() {
  return (
    <Container fluid style={{width:100+'%',padding:0}}>
      <HeroSection />
     
      <Container style={{marginTop:8+'rem'}}> 
        <div style={{justifyContent:'center' ,display:'flex', flexDirection:'column', alignItems:'center'}}>
          <h2 style={{fontSize:2.7+'rem',}}><strong>Our <span style={{color:'#0187F2'}}> Managements</span></strong></h2>
          <span className='headparagraph' style={{fontSize:0.8+"rem", justifyContent:'center', alignItems:'center', textAlign:'center'}}>Our Core values are based on professionalism,<br/> integrity and respect for each client and project we undertake. </span>
        </div>
        <br/><br/>
        <ManagementSlide />
      </Container>
      <CompanyStatement />
     
      <Footer />
      

    </Container>
  );
}

export default AboutPage;
