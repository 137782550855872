import React from 'react'
import { Container, Image, Col, Row } from 'react-bootstrap'
import corevalueimg from '../../../assets/corevalueimg.png';
import AnimatedDiv from '../../AnimatedDiv';

function OurValue() {
  return (
    <Container style={{marginTop:9+'rem'}}>
        <Row>
        <Col  
        xs={12}
         sm={12}
         md={6}
         lg={6}>
          <AnimatedDiv transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <Image src={corevalueimg} width={100 + "%"} height={100 + "%"} />
          </AnimatedDiv>
        </Col>
        <Col 
         xs={12}
         sm={12}
         md={6}
         lg={6}>
          <AnimatedDiv transition={{ duration: 0.5, delay: 0.7, timingFunction: 'easeOut' }}>
          <h2 className="introsection-header">
            Our  <br/><span style={{ color: "#0187F2" }}>Core Value</span>
          </h2>
          <p className="introsection-paragraph">
          Our Core values are based on professionalism, integrity and respect for each client and project we undertake. We are very open in our business dealings, and work with the most dedicated and experienced engineers, Architects and others profesionals while also laying emphasis on teamwork, innovation and applying the highest safety measures in all our projects.
          </p>
          <p className="introsection-paragraph">
          We at Craniun are Passionate about what we do and what we do matter to us, so we make sure we deliver on our set out goals with precision.
          Service delivery is the hallmark of our trade.
          </p>

          </AnimatedDiv>
        </Col>
        </Row>
      
    </Container>
  )
}

export default OurValue
