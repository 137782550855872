import React from 'react'
import { Container, Image, Col, Row } from 'react-bootstrap'
import corevalueimg from '../../../assets/corevalueimg.png';

function Introduction() {
  return (
    <Container style={{marginTop:9+'rem'}}>
        <Row>
        <Col 
         xs={12}
         sm={12}
         md={6}
         lg={6}>
          <h2 className="introsection-header">
            Emirate  <br/><span style={{ color: "#0187F2" }}>Core Value</span>
          </h2>
          <p className="introsection-paragraph">
          Denver apartments is a five bedroom semi detached terrace duplex with BQ designed for a modern family with a flair for discreet architecture and space management infused with nature and beautiful landscaping, Denver apartments is situated 

          </p>
          <p className="introsection-paragraph">
          At the serene highbrow area of katampe extension right in the heart of the fenderal capital territory (FCT) Abuja. Denver apartments is a gated community unique in its own way and studded with modern amenities for luxurious living
          </p>

          <div>
            <h6>Facilities</h6>
            <ul style={{display:'flex',flexWrap:'wrap', gap:2+'rem',alignItems: 'flex-start',justifyContent: 'flex-start',lineHeight:-20+'px',marginLeft:-1+'rem'}}>
           
                <li>CCTV</li>
                <li>Ample Parking</li>
                <li>Smart Entry</li>
               
               
              
            </ul>
            <ul style={{display:'flex',flexWrap:'wrap', gap:2+'rem',alignItems: 'flex-start',justifyContent: 'flex-start',lineHeight:-20+'px',marginLeft:-1+'rem'}}>
           
          
           <li  >Landscaping</li>
           <li  >serene Environment</li>
          
         
       </ul>
          </div><br/>

          
        </Col>
        <Col  
        xs={12}
         sm={12}
         md={6}
         lg={6}>
          <Image src={corevalueimg} width={100 + "%"} height={100 + "%"} />
        </Col>
        </Row>
      
    </Container>
  )
}

export default Introduction
