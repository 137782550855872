import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import introimage from "../../../assets/introimage.png";

import "../../homepage/homepage.css";
import AnimatedDiv from "../../AnimatedDiv";

function Introduction() {
  return (
    <Container className="introsection">
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
        <AnimatedDiv transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <h2 className="introsection-header">
            <span style={{ color: "#0187F2" }}>Introduction</span>
          </h2>
          <p className="introsection-paragraph">
            Cranium construction company limited was incorporated in 2018 to
            provide a wide range of real estate, construction and engineering
            services and solutions to the Nigerian market. Our strategic intent
            is to establish lasting relationships with our clients by exceeding
            their expectations and gaining their trust through exceptional
            performance, guaranteed by every member of the construction team. As
            we aspire to be the contractor and employer of choice by safely and
            consistently delivering successful and innovative capital projects
            and services, the strategy for our firm is set by the Board of
            Directors. We move to the needs of our clients and utilize skills
            development, socio- economic development, enterprise development,
            employment equity and shared ownership to implement construction
            projects of highest quality on time and within budget.
          </p>
          </AnimatedDiv>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <AnimatedDiv transition={{ duration: 0.5, delay: 0.7, timingFunction: 'easeOut' }}> <Image src={introimage} width={100 + "%"} /></AnimatedDiv>
         
        </Col>
      </Row>
    </Container>
  );
}

export default Introduction;
