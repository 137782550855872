import React from 'react';
import { Container } from 'react-bootstrap';
import HeroSection from '../contact/components/HeroSection';




import './contact.css'





function Contact() {
  return (
    <Container fluid style={{width:100+'%',padding:0}}>
      <HeroSection />
      

    </Container>
  );
}

export default Contact;
