import React from 'react'
import StickyNavbar from '../../reuse-component/Navbar/StickyNavbar'
import Denverbg from '../../../assets/Denverbg.png'
import Container from 'react-bootstrap/esm/Container'

function HeroSection() {
   
    
  return (
    <div id="hero" style={{ width: '100%', backgroundImage: `url(${Denverbg})`, backgroundSize:'cover' ,height: '100vh', marginTop: '0px', paddingLeft: 0, paddingRight: 0 }}>
       
     <StickyNavbar />
     <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
     <Container className="introsectionxx" style={{justifyContent:'center' ,display:'flex', flexDirection:'column', alignItems:'center',color:'#ffffff'}}>
        <h1 style={{fontSize:3.2+'rem', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
          <strong>Finished Projects</strong>
        </h1>
        <p style={{fontSize:0.8+"rem", justifyContent:'center', alignItems:'center', textAlign:'center'}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla 
          convallis libero et arcu posuere, vitae facilisis nulla ultrices. 
          Vivamus sit amet
        </p>
      </Container>
</div>


    </div>
  )
}

export default HeroSection
