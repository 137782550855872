import React from 'react';
import { Container } from 'react-bootstrap';
import HeroSection from '../about/components/HeroSection';


import './aboutpage.css'
import Introduction from './components/Introduction';
import Mission from './components/Mission';
import OurValue from './components/OurValue';
import CompanyStatement from './components/CompanyStatement';
import Testimonial from '../../components/homepage/components/Testimonial';
import Footer from '../../components/homepage/components/Footer';

import './aboutpage.css'


function AboutPage() {
  return (
    <Container fluid style={{width:100+'%',padding:0}}>
      <HeroSection />
      <Introduction />
      <Mission />
      <OurValue />
      <CompanyStatement />
      <Testimonial />
      <Footer />
      

    </Container>
  );
}

export default AboutPage;
