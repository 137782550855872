import React from "react";
import Carousel from "react-multi-carousel";
import { Image, Container } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import user1 from '../../../assets/user1.png'

import '../management.css'


import { ExternalLink } from "react-external-link";


const Dataset = [
  {
    id: "1",
    captionTitle: "Eng. Abdullahi Sadiq",
    sub: "CEO",
    captionDescription:
      " Cranium construction company limited was incorporated in 2018 to provide a wide range of real estate, construction and engineering services and solutions to the Nigerian market.Our strategic intent is to establish lasting relationships with our clients by exceeding their expectations and gaining their trust through exceptional performance, guaranteed by every member of the construction team.",
    imageUrl: user1,
   
  },
  {
    id: "2",
    captionTitle: "Eng. Abdullahi Sadiq",
    sub: "CEO",
    captionDescription:
      " Cranium construction company limited was incorporated in 2018 to provide a wide range of real estate, construction and engineering services and solutions to the Nigerian market.Our strategic intent is to establish lasting relationships with our clients by exceeding their expectations and gaining their trust through exceptional performance, guaranteed by every member of the construction team.",
    imageUrl: user1,
   
  },
  {
    id: "3",
    captionTitle: "Eng. Abdullahi Sadiq",
    sub: "CEO",
    captionDescription:
      " Cranium construction company limited was incorporated in 2018 to provide a wide range of real estate, construction and engineering services and solutions to the Nigerian market.Our strategic intent is to establish lasting relationships with our clients by exceeding their expectations and gaining their trust through exceptional performance, guaranteed by every member of the construction team.",
    imageUrl: user1,
   
  },
  {
    id: "4",
    captionTitle: "Eng. Abdullahi Sadiq",
    sub: "CEO",
    captionDescription:
      " Cranium construction company limited was incorporated in 2018 to provide a wide range of real estate, construction and engineering services and solutions to the Nigerian market.Our strategic intent is to establish lasting relationships with our clients by exceeding their expectations and gaining their trust through exceptional performance, guaranteed by every member of the construction team.",
    imageUrl: user1,
   
  },
 
 
];



function ProjectList() {
  return (
    <>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3.5, // Adjusted to show 2.5 items
              partialVisibilityGutter: 30,
            },
            mobile: {
              breakpoint: {
                max: 550,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 551,
              },
              items: 1.5,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {Dataset.map((item) => (
            <Container style={{width:100+"%"}}>

              <ExternalLink >
              <figure className="figure-container">
                <Image
                  width={100 + "%"}
                  src={item.imageUrl}
                  alt="Description of the image"
                />
                {/* coment */}
                <figcaption style={{ padding: 0.7 + "rem" }} className="caption">
                  <div style={{display:"flex",gap:5}}>
                    <div>
                    <h6 style={{fontFamily:"Clash Display"}}>
                  <strong>{item.captionTitle}</strong>
                </h6>
                <span>{item.sub}</span>
                <p style={{fontSize:0.7+"rem",fontFamily:"Space Mono",lineHeight:199+"%"}}>{item.captionDescription}</p>
                    </div>
                   
                  </div>
               
              </figcaption>
              </figure>
              </ExternalLink>
              
              
            </Container>
          ))}
        </Carousel>
      
    </>
  );
}

export default ProjectList;
