import React, { useState } from "react";
import HeroSection from './components/HeroSection';
import { Container, Row, Col, Image, Modal, ModalFooter } from "react-bootstrap";
import { Link } from "react-router-dom";
import './emirate.css';
import Footer from '../../components/homepage/components/Footer';
import Introduction from './components/Introduction';
import Testimonial from '../homepage/components/Testimonial';
import Address from './components/Address';
import corevalueimg from "../../assets/corevalueimg.png";
import { BsHouseCheck, BsLayoutTextWindow, BsTags } from "react-icons/bs";
import AnimatedCursors from "../AnimatedCursors";
import Carousel from 'react-bootstrap/Carousel';

function Emirate() {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setSelectedItem(item);
    setShow(true);
  };

  const Data = [
    {
      id: "1",
      title: "4 bedroom Denver",
      price: "83 Million",
      image: corevalueimg,
      description:
        "Vivamus sit amet augue ac sapien bibendum dapibus nec vitae metus. Fusce nec metus vel justo sollicitudin pretium",
      otherimage:[corevalueimg, corevalueimg, corevalueimg]
    },
    {
      id: "2",
      title: "2 bedroom Denver",
      price: "53 Million",
      image: corevalueimg,
      description:
        "Vivamus sit amet augue ac sapien bibendum dapibus nec vitae metus. Fusce nec metus vel justo sollicitudin pretium",
        otherimage:[corevalueimg, corevalueimg, corevalueimg]
    },
  ];

  return (
    <Container fluid style={{ width: '100%', padding: 0 }}>
      <HeroSection />
      <Introduction />
      <Container style={{ marginTop: '4rem' }}>
        <div>
          <h1 style={{ fontSize: '3.2rem' }}>
            <strong>Finished Projects</strong>
          </h1>
          <p style={{ fontSize: '0.8rem' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            convallis libero et arcu
            <br /> posuere, vitae facilisis nulla ultrices. Vivamus sit amet
          </p>
        </div>
        <Row>
          {Data.map((item) => (
            <Col style={{ marginTop: '2rem' }} xs={12} sm={12} md={6} lg={6} key={item.id}>
              <div
                onClick={() => handleShow(item)}
                style={{ display: "flex", flexDirection: "column", cursor: 'pointer' }}
              >
                <Image src={item.image} width="100%" alt="property" />
                <div style={{ marginTop: '1rem' }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <span style={{ fontSize: '1rem' }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "21px",
                          alignItems: "center",
                        }}
                      >
                        <BsHouseCheck size={23} />
                        {item.title}
                      </div>
                    </span>
                    <span style={{ fontSize: '1rem' }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "21px",
                          alignItems: "center",
                        }}
                      >
                        <BsTags size={23} />
                        {item.price}
                      </div>
                    </span>
                  </div>
                  <div>
                    <Row style={{ marginTop: '0.5rem' }}>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        <BsLayoutTextWindow size={21} />
                      </Col>
                      <Col xs={11} sm={11} md={11} lg={11}>
                        {item.description}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Address />
      <Testimonial />
      <Footer />

      {selectedItem && (
        <Modal show={show} onHide={handleClose}>
          <AnimatedCursors />
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '0.9rem' }}>Project Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Carousel>
                {selectedItem.otherimage.map((image, index) => (
                  <Carousel.Item key={index}>
                    <Image src={image} width="100%" alt={`property ${index}`} />
                  </Carousel.Item>
                ))}
              </Carousel>
              <br/>
              <div style={{ marginTop: '1rem' }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <span style={{ fontSize: '0.9rem' }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "21px",
                        alignItems: "center",
                      }}
                    >
                      <BsHouseCheck size={23} />
                      {selectedItem.title}
                    </div>
                  </span>
                  <span style={{ fontSize: '0.9rem' }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "21px",
                        alignItems: "center",
                        fontSize:0.9+'rem'
                      }}
                    >
                      <BsTags size={23} />
                      {selectedItem.price}
                    </div>
                  </span>
                </div>
                <div>
                  <Row style={{ marginTop: '0.5rem' }}>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      <BsLayoutTextWindow size={21} />
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} style={{fontSize:0.9+'rem'}}>
                      {selectedItem.description}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Modal.Body>
          <ModalFooter>
            <Link to="/contact">
              <button style={{
                backgroundColor: "#031724",
                borderRadius: "5px",
                marginLeft: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
                paddingBottom: '10px',
                marginTop: '2px',
                textDecoration: 'none',
                color: "#ffffff"
              }}>
                Get Quote
              </button>
            </Link>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
}

export default Emirate;
