import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import introimage from "../../../assets/introimage.png";


import "../homepage.css";
import AnimatedDiv from "../../AnimatedDiv";

function Introduction() {
  return (
    <Container className="introsection">
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <AnimatedDiv  transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <h2 id="intro" className="introsection-header">
            Living Spaces Designed With{" "}
            <span style={{ color: "#0187F2" }}>Perfection</span>
          </h2>
        </AnimatedDiv>
        <AnimatedDiv  transition={{ duration: 0.5, delay: 0.7, timingFunction: 'easeOut' }}>
          <p className="introsection-paragraph">
            At the forefront of disruption in the real estate industry, Cranium
            Investment Limited is shaping new lifestyles with a focus on the
            integration of revolutionary technology into residential and
            commercial real estate using sustainable methods.{" "}
          </p>
          <button
            style={{ color: "#0187F2", marginBottom: 2 + "rem" }}
            className="btn btn-light"
          >
            See more
          </button>
          </AnimatedDiv>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
        <AnimatedDiv  transition={{ duration: 0.5, delay: 0.5, timingFunction: 'easeOut' }}>
          <Image src={introimage} width={100 + "%"} />
          </AnimatedDiv>
        </Col>
      </Row>
    </Container>
  );
}

export default Introduction;
